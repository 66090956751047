<template>
  <div>
    <section id="hiSearch" class="light-gray">
      <div class="row q-mx-auto justify-between">
        <div class="col">
          <q-input
            flat
            bottom-slots
            :color="getColor"
            type="search"
            clearable
            clear-icon="fal fa-times"
            input-class="search"
            style="max-width: 960px"
            placeholder="Search for..."
            v-model="searchFor"
            @keyup.enter="go"
          >
            <template v-slot:prepend>
              <i class="fas fa-search" />
            </template>
          </q-input>
        </div>
      </div>
    </section>

    <section v-if="result" id="hiFilter">
      <div class="row q-mx-auto justify-between items-center">
        <div class="col-shrink q-py-md q-pr-md">
          <h4>Filter on</h4>
        </div>
        <div class="col q-py-md q-pr-md">
          <span :key="t" v-for="t in result.types">
            <q-btn
              :icon="checkActive(t)"
              @click="filter(t)"
              dense
              no-caps
              flat
              color="#06c"
              :label="t === 'CollectionItem' ? 'Historiana' : t"
            />
          </span>
        </div>
      </div>
    </section>

    <section class="row q-pa-md" v-if="result" id="card-holder">
      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md">
          <div :key="i" v-for="(r, i) in filteredData" class="column">
            <Card
              :item="r"
              :index="i"
              :thumb="r.node.url"
              :type="r.type"
              :csstype="r.csstype"
              :title="r.node.title"
              :btnReference="r.collection ? true : false"
              :references="r.collection"
              :showAddToMyHistoriana="true"
            >
              {{ r.node.description }}
            </Card>
          </div>

          <!-- <q-card :key="r.id" class="q-mr-md" v-for="r in filteredData">
            <q-bar>{{ r.type }}</q-bar>
            <q-img
              v-if="r.node.url"
              contain
              style="max-width: 300px; max-height: 240px"
              :src="r.node.url"
            />
            <div style="max-height: 20vh; overflow: hidden" class="text-h6">
              {{ r.node.title }}
            </div>

            <div v-if="r.collection">
              <p>
                Part of collection
                <a
                  :href="`/historical-content/source-collections/${r.collection.slug}`"
                >
                  <strong>{{ r.collection.name }}</strong>
                </a>
              </p>
            </div>
            <q-card-actions> </q-card-actions>
          </q-card> -->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { API } from "@/tic";
import { mapGetters } from "vuex";
import getColor from "../mixins/getColor.mixin.js";
import Card from "../tags/Card";

export default {
  name: "Search",
  mixins: [getColor],
  components: { Card },

  data() {
    return {
      searchFor: null,
      result: null,
      activeFilter: [],
    };
  },

  computed: {
    ...mapGetters("user", ["uuid"]),

    filteredData(e) {
      if (this.activeFilter.length) {
        return this.result.data.filter((e) => {
          return this.activeFilter.includes(e.type);
        });
      } else {
        return this.result.data;
      }
    },
  },

  methods: {
    checkActive(type) {
      console.log("t: ", type);
      return this.activeFilter.includes(type) ? "done" : "";
    },

    filter(type) {
      if (this.activeFilter.includes(type)) {
        this.activeFilter = this.activeFilter.filter((e) => e !== type);
      } else {
        this.activeFilter.push(type);
      }
    },

    go() {
      const vm = this;
      console.log(`search for ${this.searchFor} ${this.uuid}`);
      vm.$q.loading.show({ message: "Searching..." });
      API.get("/search/eu", {
        params: { searchFor: this.searchFor, uuid: this.uuid },
      }).then((r) => {
        //console.log("results: ", r);
        this.result = r.data;
        vm.$q.loading.hide();

        //this.activeFilter = r.data.types
      });
    },
  },
};
</script>

<style>
</style>
